import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject } from "rxjs";
import { tap, map } from "rxjs/operators";
import { State, toDataSourceRequestString } from "@progress/kendo-data-query";
import { GridDataResult } from "@progress/kendo-angular-grid";
import { DataService } from "@shared/async-services/data.service";

@Injectable()
export class GridDataExportEmailService extends BehaviorSubject<any[]> {
  public state: State = {
    skip: 0,
    take: 10,
  };

  // private BASE_URL = "agent/list";
  constructor(
    private dataService: DataService,
  ) {
    super([]);
  }

  public read(
    action: string = "",
    searchtext: string = "",
    outerSearch: any = null
  ) {
    this.fetch(searchtext, 'Insurance/ExportEmail/GetBrokerEmailRenewalList', outerSearch)
      .pipe(tap((data) => { }))
      .subscribe((data) => {
        // this.rgService.totalSearchRecords = data.total;
        super.next(data);
      });
  }

  public readCommman(
    action: string = "",
    searchtext: string = "",
    outerSearch: any = null
  ) {
    this.fetch(searchtext, 'Insurance/ExportEmail/GetBrokerEmailRenewalList/' + action, outerSearch)
      .pipe(tap((data) => { }))
      .subscribe((data) => {
        // this.rgService.totalSearchRecords = data.total;
        super.next(data);
      });
  }
  
  public fetch(
    //action: string = "",
    searchtext: string = "",
    endpoint: string = "",
    outerSearch: any = null
  ): Observable<any> {


    let queryStr = `${toDataSourceRequestString(this.state)}`;
    const params = queryStr.split("&");
    const objSearch = Object.assign(outerSearch, {});
    if (queryStr.indexOf("sort") > -1) {
      objSearch.pageIndex = +params[0].split("=")[1] - 1;
      objSearch.sortCol = params[1].split("=")[1].split("-")[0];
      objSearch.sortOrder = params[1].split("=")[1].split("-")[1] == "asc" ? 0 : 1;
      objSearch.pageSize = params[2].split("=")[1];
    } else {
      objSearch.pageIndex = +params[0].split("=")[1] - 1;
      objSearch.pageSize = params[1].split("=")[1];
      objSearch.searchText = searchtext;

    }

    return this.dataService.post(`${endpoint}`, objSearch).pipe(
      // Process the response.
      map((data: any): any => {
        return {
          data: data.items,
          total: data.totalCount,
        };
      })
    );

  }

  queryAll(
    action: string = "",
    searchtext: string = "",
    endpoint: string = "",
    agentId: number,
    outerSearch: any = null
  ): Observable<GridDataResult[]> {
    this.state.skip = 0;
    this.state.take = 99999;
    return this.fetch(searchtext, endpoint, outerSearch);
  }
  defaultLoad(
    action: string = "",
    searchtext: string = "",
    endpoint: string = "",
    agentId: number,
    outerSearch: any = null
  ): Observable<GridDataResult[]> {
    this.state.skip = 0;
    this.state.take = 10;
    return this.fetch(searchtext, endpoint, outerSearch);
  }
}
