/** Angular core dependencies */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

/** Third party modules */
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

/** Custom Components */



/** Custom Components Registration*/
export const COMPONENTS = [
];

@NgModule({
  imports: [
    /** Angular core dependencies */
    CommonModule,
    RouterModule,
    /** Third party modules */
    NgbModule,
    TranslateModule,
  ],
  declarations: COMPONENTS,
  exports: COMPONENTS
})
export class ComponentsModule { }
